import React from "react";
import Rsvp from "./components/Rsvp";
import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <Rsvp />
    </div>
  );
}
